<template>
  <div class="gift-travel-section relative md:mb-12">
    <div
      class="
        flex flex-col
        md:flex-row
        px-8
        md:px-16
        xl:px-24
        py-12
        justify-between
        items-center
        gap-8
      "
    >
      <!-- Left Side: Content -->
      <div class="flex flex-col justify-start left-side">
        <p
          class="
            mt-4
            md:mt-6
            text-3xl
            font-bold
            tracking-tight
            sm:text-6xl sm:leading-tight
            text-center
            md:text-left
          "
        >
          Gift the joy of travel
        </p>
        <p
          class="
            text-sm
            md:text-xl
            lg:text-2xl
            text-ternGray
            md:text-black
            font-medium
            pt-4
            md:py-8
            text-center
            md:text-left
          "
        >
          Surprise your loved ones with the ultimate travel experience.
        </p>

        <!-- How It Works -->
        <div class="py-6">
          <p class="text-lg md:text-xl font-semibold">Here’s how it works:</p>
          <ul class="list-none space-y-3 pt-4 text-sm md:text-base lg:text-lg">
            <li
              v-for="(step, index) in steps"
              :key="index"
              class="flex items-start"
            >
              <span class="numbers">{{ index + 1 }}</span>
              {{ step }}
            </li>
          </ul>
        </div>

        <!-- Call to Action Button -->
        <div
          class="pt-4 md:pt-8 flex items-center justify-center md:justify-start"
        >
          <Button class="flex items-center justify-center" />
        </div>
      </div>

      <!-- Right Side: Image -->
      <div
        class="
          relative
          flex
          justify-center
          items-center
          md:block
          desktop-main-image
        "
      >
        <img
          src="../assets/gift_travel/travel_gift_hero.jpg"
          alt="Gift Travel Hero"
          class="
            w-full
            max-w-xs
            sm:max-w-sm
            md:max-w-md
            lg:max-w-lg
            mx-auto
            overflow-hidden
            bg-white
            border border-gray-200
            rounded-lg
            shadow-lg
            dark:bg-gray-800 dark:border-gray-700
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from './Buttons/TernButtonGifting.vue';

export default {
  name: 'GiftTravel',
  components: {
    Button,
  },
  data() {
    return {
      steps: [
        'Choose between gifting a few trips or months of our unlimited plan.',
        'Add a personalized message.',
        'Send it instantly or schedule it for the perfect moment.',
      ],
    };
  },
};
</script>

<style scoped>
.gift-travel-section {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.17) 0%,
    rgba(255, 171, 187, 0.17) 40%,
    rgba(253, 173, 0, 0.17) 67%,
    rgba(255, 255, 255, 0.17) 100%
  );
}
.numbers {
  @apply mr-2 mt-0.5 p-2 w-6 h-6 rounded-full bg-black text-white flex items-center justify-center text-sm font-bold;
  min-width: 1.5rem;
  min-height: 1.5rem;
}
.desktop-main-image {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.left-side {
  width: 100%;
}
.group:hover .object-cover,
.group:active .object-cover {
  transform: scale(1.05);
}
.group .bg-gradient-to-t {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  transition: background-image 1s ease-in-out;
}
img {
  max-width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .left-side {
    width: 50%;
    min-width: 450px;
  }
  .desktop-main-image img:last-child {
    top: 10%;
    right: 5%;
  }
}
</style>
